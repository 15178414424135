<template>
  <v-card v-bind="cardProperties" elevation="8">
    <v-img
      :src="photoUrl"
      :height="imageHeight"
      width="100%"
      class="white--text align-end"
      gradient="180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)"
    >
      <v-card-title
        v-if="title"
        class="text-truncate font-weight-light subtitle-1"
        style="width: 95%"
      >
        {{title}}
      </v-card-title>
    </v-img>

    <slot name="actions"></slot>

    <v-card-actions class="float-right" v-if="!disabled">
      <v-btn icon @click="edit">
        <v-icon>fa fa-edit</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="babel" type="text/babel">
import bannerItemMixin from 'components/bannerEditor/bannerItem/bannerItemMixin.js'
export default {
  mixins: [bannerItemMixin],
  methods: {
    edit() {
      this.$apopup.base({
        width: '650px',
        defaultValue: this.$eagleLodash.cloneDeep(this.instance),
        bodySlot: () => import('./bannerItemPhoto/bannerItemPhotoForm.vue'),
        applyCallback: this.updateBannerPhoto,
      })
    },
    async updateBannerPhoto(data) {
      try {
        await this.$api.collection.bannerPhotoApi.update(data.id, data)
        this.$emit('refresh')
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t('error.unexpect'),
          this.$t('save.failure')
        )
        throw error
      }
    },
  },
  computed: {
    photoUrl() {
      return this.$helper.getPhotoUrl(this.instance.photo, ['small', 'tiny'])
    },
    title() {
      return this.instance.title
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
